<template>
  <v-dialog :persistent='true' overlay-opacity='100'
      v-model="dialog2"
      width="500"
    >
 

      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Please Enter One Time Passcode
        </v-card-title>

        <v-card-text class='mt-5'>
         {{loginstate ? "Please Check your phone for a one time passcode to login" : "Please Check your phone for a one time passcode to register your phone number"}}

         <v-otp-input
  length="6"
  type="number"
  :disabled="loading"
   v-model="smscode"
   @finish="regPhone"
></v-otp-input>
<span style='color:red'>{{message}}</span>
                            
        </v-card-text>

     
        
      </v-card>
    </v-dialog>
</template>

<script>
export default {
    props : {
        message : String,
        loginstate : Boolean,
        dialog2 : Boolean,

    },
    methods: {
        regPhone(){
             this.$emit('finish',this.smscode)
        }
    },
    data(){
        return{
            loading : false,
            smscode : null,
        }
    }
}
</script>

<style>

</style>