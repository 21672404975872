import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/compat/storage";

const firebaseConfig = {
	apiKey: "AIzaSyDzJTSYxA2xp_ZSnTeQn9NOsxi8_XjecdA",
	authDomain: "waproject-5dc59.firebaseapp.com",
	projectId: "waproject-5dc59",
	storageBucket: "waproject-5dc59.appspot.com",
	messagingSenderId: "85334372639",
	appId: "1:85334372639:web:d2d72fecfb3ddf1898c02a"
};

const admin = firebase.initializeApp(firebaseConfig);
const auth = admin.auth("waproject-5dc59.appspot.com");
const db = admin.firestore();
const { currentUser } = admin;

// firebase collections

export { auth, currentUser, db };
