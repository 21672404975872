<template>
  <v-container>
    <v-card>
      <v-card-title>
        Admin Panel
      </v-card-title>
      <v-card-text>
        <v-expansion-panels
          v-model="panel"
          multiple
          accordion
        >
          <v-expansion-panel>
            <v-expansion-panel-header>
              Add new user
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-form ref='form'>
                <v-select
                  :items='["User","Admin"]'
                  label='Access Level'
                  :rules="requiredRule"
                  v-model='access'
                >
                </v-select>
                <v-select
                  v-if="role !== 'Admin' && role !== 'User'"
                  :items='orgs'
                  label='Office/Law Firm '
                  v-model='selectedOrg'
                  item-text="name"
                  item-value="id"
                >
                </v-select>
                <v-text-field
                  label='Email Address'
                  :rules="requiredRule"
                  v-model='email'
                >
                </v-text-field>
                <v-text-field
                  label='Name (First + Last)'
                  :rules="requiredRule"
                  v-model='name'
                >
                </v-text-field>

                <v-select :disabled="access !== 'User'"
                  :items='["Attorney","Social Worker"]'
                  label='Type'
                  :rules="access !== 'User'  ? [] :  requiredRule"
                  v-model='typeOfUser'
                >
                </v-select>

                <v-btn
                  :loading='btnloading'
                  @click='submit'
                  color='primary'
                >
                  Submit
                </v-btn>
              </v-form>
            </v-expansion-panel-content>
          </v-expansion-panel>

        
            <v-expansion-panel>
              <v-expansion-panel-header>
              Disable Users
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-form ref='form2'>
                <v-select
                  :items='users'
                  label='Users'
                  :rules="requiredRule"
                  v-model='disableUser'
                  item-text="email"
                  item-value="uid"
                >
                </v-select>
               
                <v-btn
                  :loading='btnloading2'
                  @click='disableUserHandler'
                  color='primary'
                >
                  Submit
                </v-btn>
              </v-form>
            </v-expansion-panel-content>
          </v-expansion-panel>
          

          <v-expansion-panel  v-if="role !== 'Admin' && role !== 'User'">
              <v-expansion-panel-header>
              Promote Users
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-form ref='form2'>

                <v-select 
                  :items='["User","Admin","Super_Admin","Program_Admin"]'
                  label='Role'
                  :rules="requiredRule"
                  v-model='promoteUserRole'
                >
                </v-select> 

                <v-select
                :disabled="promoteUserRole !== 'User' && promoteUserRole !== 'Admin'"
                  :items='orgs'
                  label='Office/Law Firm '
                  v-model='promoteOrg'
                  item-text="name"
                  item-value="id"
                >
                </v-select>
                


                <v-select
                  :items='users'
                  label='Users'
                  :rules="requiredRule"
                  v-model='promoteUser'
                  item-text="email"
                  item-value="uid"
                >
                </v-select>
                

             


                <v-btn
                  :loading='btnloading2'
                  @click='promoteUserHandler'
                  color='primary'
                >
                  Submit
                </v-btn>
              </v-form>
            </v-expansion-panel-content>
          </v-expansion-panel>


        </v-expansion-panels>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import { mapFields } from "vuex-map-fields";
import axios from "axios";
import { disabledUser, getAllOrg,getAllUsers ,promoteUser} from "../dataservices/adminDataService";
export default {
  async mounted() {
    this.orgs = await getAllOrg();
    this.users = await getAllUsers();
  },
  computed: {
    ...mapFields(["user","role"]),
  },
  methods: {
    async promoteUserHandler(){
      await promoteUser(this.promoteUser,this.promoteUserRole);
    },
    async disableUserHandler(){
      this.btnloading2 = true;
      let valid = this.$refs.form2.validate();
      if (valid) {
        try {
          await disabledUser(this.disableUser);
          this.btnloading2 = false;
          location.reload();
        } catch (e) {
          alert("something went wrong, please check your inputs");
          this.btnloading2 = false;
        }
      } else {
        return;
      }
    },
    async submit() {
      this.btnloading = true;
      let valid = this.$refs.form.validate();
      if (valid) {
        try {
          await axios.post(
            "https://dp-02-db2a5.wl.r.appspot.com/internal/createUser",
            {
              email: this.email,
              name: this.name,
              role: this.access,
              title: this.typeOfUser,
              org : this.selectedOrg
            }
          );
          this.btnloading = false;
        } catch (e) {
          alert("something went wrong, please check your inputs");
          this.btnloading = false;
        }
      } else {
        return;
      }
    },
  },
  data() {
    return {
      promoteOrg : null,
      promoteUserRole : null,
      promoteUser : null,
      disableUser : null,
      orgs : [],
      users : [],
      btnloading: false,
      btnloading2 : false,
      requiredRule: [(v) => !!v || "This field is required"],
      email: null,
      name: null,
      typeOfUser: "",
      access: "User",
      panel: [0,1],
      selectedOrg : null,
    };
  },
};
</script>

<style>
</style>