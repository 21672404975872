<template>
    <v-card>
      <v-card-title>
      Activity Log
      <v-spacer></v-spacer>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Search"
        single-line
        hide-details
      ></v-text-field>
      </v-card-title>
  <v-data-table
    :headers="headers"
    :items="logs"
    :items-per-page="15"
    class="elevation-1"
    :search="search"
  ></v-data-table>
  </v-card>
</template>

<script>
import { getLogs } from '../dataservices/adminDataService';

export default {
  async mounted(){
    this.logs = await getLogs();
  },
    data(){
        return{
          logs : [],
            search : "",
            headers : [ 
                 { text: 'Timestamp', value: 'timestamp' },
                 { text: 'Method', value: 'method' },
                 { text: 'Requested URL', value: 'url' },
                 { text: 'userAgent', value: 'userAgent' },
                 { text: 'requestBody', value: 'requestBody' },
                 { text: 'user', value: 'user' },
                 { text: 'uid', value: 'uid' },

            ],
     
        }
    },
}
</script>

<style>

</style>