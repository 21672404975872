<template>
  <div class='ma-2'>
    <v-card-title>
      Manage Contractors
    </v-card-title>
    <v-breadcrumbs
      :items="breadcrumbsitems"
      large
    ></v-breadcrumbs>
    <v-card>
      <v-card-title>

        <v-btn
          @click='createContractor'
          class='mr-3'
        >
          Create New Contractor
        </v-btn>

        <v-btn
          v-if='selected.length > 0'
          color="error"
          :disabled='selected.length === 0'
        >
          Delete {{selected.length}} Records
        </v-btn>

        <v-spacer></v-spacer>
        <v-text-field
          dense
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
        ></v-text-field>

      </v-card-title>

      <v-data-table
        :items-per-page='30'
        item-key="id"
        v-model="selected"
        show-select
        :loading='loading'
        loading-text="Loading... Please wait"
        multi-sort
        :sort-by="sortby"
        :sort-desc="sortDesc"
        :headers="headers"
        :items="contractors"
        :search="search"
      >

        <template v-slot:item.SumGLAmount="{ item }">

          ${{ item.SumGLAmount }}

        </template>
        <template v-slot:item.actions="{ item }">
          <v-icon
            title='list all contracts associated with this contractor'
            class="mr-2"
            @click="goToListAssoicatedContractsPage(item)"
          >
            mdi-clipboard-list
          </v-icon>
          <v-icon
            class="mr-2"
            @click="editItem(item)"
            title='edit'
          >
            mdi-pencil
          </v-icon>
          <v-icon @click="deleteItem(item)">
            mdi-delete
          </v-icon>
        </template>
      </v-data-table>
    </v-card>

    <v-dialog v-model="dialog">
      <v-card>
        <v-card-title>
          <span class="text-h5">{{editMode ? "Edit Contractor: " + editedItem.SWV : "Create New Contractor"}}</span>
        </v-card-title>

        <v-card-text>
          <ContractorForm
            @close='close()'
            :editMode='editMode'
            :inComeEditedItem='editedItem'
          />

        </v-card-text>

      </v-card>

    </v-dialog>

    <v-dialog
      v-model="dialogDelete"
      max-width="500px"
    >
      <v-card>
        <v-card-title class="text-h5">Are you sure you want to delete this item?</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="closeDelete"
          >Cancel</v-btn>
          <v-btn
            color="blue darken-1"
            text
            @click="deleteItemConfirm"
          >OK</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>


<script>
import {
  getContractors,
  deleteContractor,
} from "../dataservices/contractorsDataservices";
import ContractorForm from "../components/ContractorForm.vue";
export default {
  components: {
    ContractorForm,
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },
  methods: {
    goToListAssoicatedContractsPage(item) {
      this.$router.push("contractsByContractor/" + item.SWV);
    },
    createContractor() {
      this.editMode = false;
      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = item.id;
      this.dialogDelete = true;
    },

    async deleteItemConfirm() {
      await deleteContractor(this.editedIndex);
      this.closeDelete();
      location.reload();
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    async editItem(item) {
      this.editMode = true;
      this.editedItem = item;
      this.dialog = true;
    },
  },
  data() {
    return {
      breadcrumbsitems: [
        {
          text: "Home",
          disabled: false,
          href: "/",
        },
        {
          text: "Contractors",
          disabled: true,
          href: "/contractors",
        },
      ],
      editedIndex: 0,
      filesOnserver: [],
      createOrEditLoading: false,
      uploadfiles: [],
      editMode: false,
      contractors: [],
      editedItem: {
        filesBase64: [],
        ProgramTitle: "",
        Contractor: "",
        EmailAddress: "",
        ContractStatus: "",
        OrgName: "",
        StreetAddress: "",
        PO_Box: "",
        SteRmAptFlMS: "",
        City: "",
        State_Province: "",
        PostalCode: "",
        AttyNames: "",
        Manager: "",
        WSBA: "",
      },
      dialog: false,
      dialogDelete: false,
      selected: [],
      loading: true,
      sortby: [],
      sortDesc: [],
      headers: [
        {
          text: "SWV #",
          align: "start",
          filterable: true,
          value: "SWV",
        },
        { text: "Contractor Name", value: "Contractor" },
        { text: "Program Title", value: "ProgramTitle" },
        //{ text: 'Manager', value: 'Manager' },
        { text: "Email Address", value: "EmailAddress" },
        { text: "Contract Status", value: "ContractStatus" },
        //{ text: 'Atty Names', value: 'AttyNames' },
        { text: "Org Name", value: "OrgName" },
        // { text: 'Street Address', value: 'StreetAddress' },
        // { text: 'PO Box', value: 'PO_Box' },
        // { text: 'SteRmAptFlMS', value: 'SteRmAptFlMS' },
        //  { text: 'City', value: 'City' },
        //  { text: 'State', value: 'State_Province' },
        //   { text: 'Postal Code', value: 'PostalCode' },

        { text: "Actions", value: "actions", sortable: false },
      ],
      search: null,
    };
  },
  async mounted() {
    this.contractors = await getContractors();
    this.loading = false;
  },
};
</script>