<template>
  <div>
    <v-card>
      <v-card-title>
        Time Sheet
        <v-spacer></v-spacer>
        <v-btn @click='save'>
          <v-icon>
            mdi-file
          </v-icon>
          Save
        </v-btn>



      </v-card-title>
      <v-card-text>
        <v-form ref='form'>
   
          <v-data-table
    :headers="headers"
    :items="timeEntries"
    item-key="index"
    class="elevation-1"
  >
    <template v-slot:[`item.date`]="{ item }">
      <v-menu
        v-model="item.menu"
        :close-on-content-click="true"
        transition="scale-transition"
        offset-y
        max-width="290px"
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            :rules="requiredRule"
            v-model="item.date"
            label="Date"
            v-bind="attrs"
            v-on="on"
          ></v-text-field>
        </template>
        <v-date-picker v-model="item.date" no-title @input="item.menu = false"></v-date-picker>
      </v-menu>
    </template>
    
    <template v-slot:[`item.user`]="{ item }">
      <v-autocomplete
        v-model='item.user'
        :rules="requiredRule"
        :items="contractors"
        item-text="ContractorName"
        item-value="ContractorID"
        label='Time Entry User'
      >
        <template v-slot:item="{ item }">
          <v-list-item-avatar
            color="indigo"
            class="text-h5 font-weight-light white--text"
          >
            {{ item.ContractorName.charAt(0) }}
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title v-text="item.ContractorName"></v-list-item-title>
            <v-list-item-subtitle v-text="item.ContractorTitle"></v-list-item-subtitle>
          </v-list-item-content>
        </template>
      </v-autocomplete>
    </template>
   
    <template v-slot:[`item.type`]="{ item }">
      <v-autocomplete
        v-model='item.type'
       
        :items="getCategoryType()"
        :rules="requiredRule"
        item-text="name"
        item-value="code"
        label='Type'
      >
        <template v-slot:item="{ item }">
          <v-list-item-content>
            <v-list-item-title v-text="item.code"></v-list-item-title>
            <v-list-item-subtitle v-text="item.name"></v-list-item-subtitle>
          </v-list-item-content>
        </template>
      </v-autocomplete>
    </template>

    <template v-slot:[`item.hrs`]="{ item }">
      <v-text-field
      type="number"
        v-model.number='item.hrs'
        :rules="requiredRule"
        label='Hrs'
      ></v-text-field>
    </template>

    <template v-slot:[`item.mins`]="{ item }">
      <v-select
        :items=[0,15,30,45]
        v-model.number='item.mins'
        :rules="NumrequiredRule"
        label='Min'
      ></v-select>
    </template>


   

    <template v-slot:[`item.case`]="{ item}">

      <v-autocomplete
        v-model='item.type'
       
        :items="casesData"
        :rules="requiredRule"
        item-text="CaseID"
        item-value="CaseID"
        label='Case Name'
      >
        <template v-slot:item="{ item }">
          <v-list-item-content>
            <v-list-item-title>{{`Name : ${item.clientFirstName ?? ""} ${item.clientMiddleName ?? ""} ${item.clientLastName ?? ""}`}}</v-list-item-title>
            <v-list-item-subtitle>{{"Case ID : " + item.CaseID}}</v-list-item-subtitle>
          </v-list-item-content>
        </template>
      </v-autocomplete>

      <!-- <v-combobox
        :search-input.sync="item.search"
        :hide-no-data="!item.search"
        :rules="requiredRule"
        v-model='item.case'
        hide-selected
        multiple
        chips
        maxlength="10" 
        @input="formatCaseNumber(item)"
        :items="casesData"
        item-text="CaseNumbers.caseNumber"
        item-value="CaseNumbers.CaseID"
        label='Case Name'
      >
        <template v-slot:selection="{ attrs, item, parent, selected }">
          <v-chip v-bind="attrs" :input-value="selected" label>
            <span class="pr-2">{{ item }}</span>
            <v-icon @click="parent.selectItem(item)">mdi-close</v-icon>
          </v-chip>
        </template>
        <template v-slot:no-data>
          <v-list-item>
            <span class="subheading">Press Enter to Add</span>
            <v-chip class="ml-2" label small>{{ item.search }}</v-chip>
          </v-list-item>
        </template>
      </v-combobox> -->
    </template>

    <template v-slot:[`item.comment`]="{ item }">
      <v-text-field
        v-model='item.comment'
   
        label='Comment'
      ></v-text-field>
    </template>

    <template v-slot:[`item.actions`]="{ item,index }">
      <v-btn icon @click="removeEntry(index)">
        <v-icon large>mdi-delete</v-icon>
      </v-btn>
    </template>
  </v-data-table>
        </v-form>
        
      </v-card-text>

    </v-card>
    <v-btn
      class='ma-5'
      @click='addNewEntry'
      color='primary'
    >
      <v-icon class='mr-2'>
        mdi-plus
      </v-icon>
      Add Case
    </v-btn>
  </div>
</template>

<script>
import { getCases } from "../dataservices/caseDataService";
import { getContractors } from "../dataservices/contractorsDataservices";
import { createTimeEntry } from "../dataservices/timeEntryDataService";
import { mapFields } from "vuex-map-fields";
export default {
  computed: {
    ...mapFields(["role","user"]),
  },
  async mounted() {
    this.casesData = await getCases();
    this.contractors = await getContractors();
    if( this.role !== "User"){
    this.headers.push( { text: 'Time Entry User', value: 'user' },)
   }
  },
  data() {
    return {
      headers: [
        { text: 'Date', value: 'date' },
        { text: 'Type', value: 'type' },
        { text: 'Case Name', value: 'case' },
        { text: 'Hours', value: 'hrs' },
        { text: 'Minutes', value: 'mins' },
      
        { text: 'Comment', value: 'comment' },
        { text: 'Actions', value: 'actions', sortable: false },
      ],

      search: null,
      requiredRule: [(v) => !!v || "This field is required"],
      NumrequiredRule: [(v) => v !== undefined && v !== null && v !== '' || "This field is required"],
      casesData: [],
      type: [
        {
          code: "CCP",
          name: "Court/ Case Prep/ Other",
          type: "Attorney"
        },
        {
          code: "DMB",
          name: "Drafting, Motions & Briefs",
          type: "Attorney"
        },
        {
          code: "OFC",
          name: "Office Client Communication (in person except court)",
          type: "Attorney"
        },
        {
          code: "OTC",
          name: "Other Client Communication (including court and written)",
          type: "Attorney"
        },
        {
          code: "ST",
          name: "Staffing - Attorney",
          type: "Attorney"
        },
        {
          code: "AC",
          name: "Attorney Communication",
          type: "Social Workers"
        },
        {
          code: "CAP",
          name: "Case Assessment and Preparation",
          type: "Social Workers"
        },
        {
          code: "IPC",
          name: "In Person Client communication",
          type: "Social Workers"
        },
        {
          code: "OCC",
          name: "Other Client Communication",
          type: "Social Workers"
        },
        {
    "code": "REF",
    "name": "Referrals",
    "type": "Social Workers"
  },
  {
    "code": "PAC",
    "name": "Parent Contact",
    "type": "Social Workers"
  },
  {
    "code": "CWC",
    "name": "Caseworker Communication",
    "type": "Social Workers"
  },
  {
    "code": "TR",
    "name": "Transportations",
    "type": "Social Workers"
  },
  {
    "code": "CMR",
    "name": "Communicating Resources",
    "type": "Social Workers"
  },
  {
    "code": "TAI",
    "name": "Tribal Affiliation Inquiry",
    "type": "Social Workers"
  },
  {
    "code": "EXC",
    "name": "Expert Communications",
    "type": "Social Workers"
  },
  {
    "code": "CC",
    "name": "Cultural Connections",
    "type": "Social Workers"
  }
      ],
      contractors: [],
      timeEntries: [
        {
          user: null,
          type: null,
          hrs: null,
          mins: null,
          case: null,
          comment: null,
          menu: false // Add a menu property for each entry
        },
      ],
    };
  },
  methods: {
    getCategoryType(){
      const u = this.contractors.find(c => c.ContractorEmail === this.user.data.email);
      switch(u?.ContractorTitle){
        case "Attorney":
        return this.type.filter(t => t.type === "Attorney");
        case "Social Worker":
        return this.type.filter(t => t.type === "Social Workers");
        default :
        return this.type;
      }
      
    },
    formatCaseNumber(item) {
      item.case.forEach((c,i) => {
        let value = c.replace(/[^0-9]/g, '');
      let formattedValue = '';
      for (let i = 0; i < value.length; i++) {
        if (i === 2 || i === 3 || i === 8) {
          formattedValue += '-';
        }
        formattedValue += value[i];
       
      }
      item.case[i] =  formattedValue;
      })
     
      
    },


    async save() {
      let valid = this.$refs.form.validate();
      if (valid) {
        await createTimeEntry(this.timeEntries);
      }
    },
    removeEntry(i) {
      if(this.timeEntries.length > 1){
        this.timeEntries.splice(i,1);
      }
    },
    addNewEntry() {
      this.timeEntries.push({
        date: null,
        user: null,
        type: null,
        hrs: null,
        case: null,
        comment: null,
        menu: false // Initialize menu property for new entry
      });
    },
  },
};
</script>

<style>
</style>