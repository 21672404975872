<template>
  <v-container fluid>
    <v-form ref="raceForm" @submit.prevent="validateForm">
      <v-row>
        <v-col cols="12">
          <label>Race/Ethnicity as reported by client:</label>
          <v-checkbox dense 
            v-model="raceData.RaceAmericanIndian"
            label="American Indian or Alaska Native"
          ></v-checkbox>
          <v-text-field
            v-if="raceData.RaceAmericanIndian"
            v-model="raceData.DetailsAmericanIndian"
            label="Enter details (e.g., Navajo Nation, Blackfeet Tribe, etc.)"
            :rules="[value => !!value || 'Detail is required']"
            dense
          ></v-text-field>
        </v-col>

        <v-col cols="12">
          <v-checkbox dense 
            v-model="raceData.RaceAsian"
            label="Asian"
            @change="checkAsian"
          ></v-checkbox>
          <v-responsive v-if="raceData.RaceAsian">
            <v-row>
              <v-col cols="6" sm="4">
                <v-checkbox dense v-model="raceData.AsianDetailsChinese" label="Chinese" @change="checkAsian"></v-checkbox>
              </v-col>
              <v-col cols="6" sm="4">
                <v-checkbox dense v-model="raceData.AsianDetailsIndian" label="Asian Indian" @change="checkAsian"></v-checkbox>
              </v-col>
              <v-col cols="6" sm="4">
                <v-checkbox dense v-model="raceData.AsianDetailsVietnamese" label="Vietnamese" @change="checkAsian"></v-checkbox>
              </v-col>
              <v-col cols="6" sm="4">
                <v-checkbox dense v-model="raceData.AsianDetailsKorean" label="Korean" @change="checkAsian"></v-checkbox>
              </v-col>
              <v-col cols="6" sm="4">
                <v-checkbox dense v-model="raceData.AsianDetailsJapanese" label="Japanese" @change="checkAsian"></v-checkbox>
              </v-col>
              <v-col cols="6" sm="4">
                <v-checkbox dense v-model="raceData.AsianDetailsMalaysian" label="Malaysian" @change="checkAsian"></v-checkbox>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="raceData.DetailsAsian"
                  label="Enter details (e.g., Pakistani, Hmong, Afghan, etc.)"
                  @input="checkAsian"
                  dense
                ></v-text-field>
                <v-alert v-if="raceData.asianError" type="error" dense>At least one subcategory or detail is required</v-alert>
              </v-col>
            </v-row>
          </v-responsive>
        </v-col>

        <v-col cols="12">
          <v-checkbox dense 
            v-model="raceData.RaceBlack"
            label="Black or African American"
            @change="checkBlack"
          ></v-checkbox>
          <v-responsive v-if="raceData.RaceBlack">
            <v-row>
              <v-col cols="6" sm="4">
                <v-checkbox dense v-model="raceData.BlackDetailsAfricanAmerican" label="African American" @change="checkBlack"></v-checkbox>
              </v-col>
              <v-col cols="6" sm="4">
                <v-checkbox dense v-model="raceData.BlackDetailsNigerian" label="Nigerian" @change="checkBlack"></v-checkbox>
              </v-col>
              <v-col cols="6" sm="4">
                <v-checkbox dense v-model="raceData.BlackDetailsEthiopian" label="Ethiopian" @change="checkBlack"></v-checkbox>
              </v-col>
              <v-col cols="6" sm="4">
                <v-checkbox dense v-model="raceData.BlackDetailsJamaican" label="Jamaican" @change="checkBlack"></v-checkbox>
              </v-col>
              <v-col cols="6" sm="4">
                <v-checkbox dense v-model="raceData.BlackDetailsSomali" label="Somali" @change="checkBlack"></v-checkbox>
              </v-col>
              <v-col cols="6" sm="4">
                <v-checkbox dense v-model="raceData.BlackDetailsHaitian" label="Haitian" @change="checkBlack"></v-checkbox>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="raceData.DetailsBlack"
                  label="Enter details (e.g., Trinidadian, Ghanaian, Congolese, etc.)"
                  @input="checkBlack"
                  dense
                ></v-text-field>
                <v-alert v-if="raceData.blackError" type="error" dense>At least one subcategory or detail is required</v-alert>
              </v-col>
            </v-row>
          </v-responsive>
        </v-col>

        <v-col cols="12">
          <v-checkbox dense 
            v-model="raceData.RaceHispanic"
            label="Hispanic or Latino"
            @change="checkHispanic"
          ></v-checkbox>
          <v-responsive v-if="raceData.RaceHispanic">
            <v-row>
              <v-col cols="6" sm="4">
                <v-checkbox dense v-model="raceData.HispanicDetailsMexican" label="Mexican" @change="checkHispanic"></v-checkbox>
              </v-col>
              <v-col cols="6" sm="4">
                <v-checkbox dense v-model="raceData.HispanicDetailsPuertoRican" label="Puerto Rican" @change="checkHispanic"></v-checkbox>
              </v-col>
              <v-col cols="6" sm="4">
                <v-checkbox dense v-model="raceData.HispanicDetailsCuban" label="Cuban" @change="checkHispanic"></v-checkbox>
              </v-col>
              <v-col cols="6" sm="4">
                <v-checkbox dense v-model="raceData.HispanicDetailsDominican" label="Dominican" @change="checkHispanic"></v-checkbox>
              </v-col>
              <v-col cols="6" sm="4">
                <v-checkbox dense v-model="raceData.HispanicDetailsSalvadoran" label="Salvadoran" @change="checkHispanic"></v-checkbox>
              </v-col>
              <v-col cols="6" sm="4">
                <v-checkbox dense v-model="raceData.HispanicDetailsGuatemalan" label="Guatemalan" @change="checkHispanic"></v-checkbox>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="raceData.DetailsHispanic"
                  label="Enter details (e.g., Colombian, Honduran, Spaniard, etc.)"
                  @input="checkHispanic"
                  dense
                ></v-text-field>
                <v-alert v-if="raceData.hispanicError" type="error" dense>At least one subcategory or detail is required</v-alert>
              </v-col>
            </v-row>
          </v-responsive>
        </v-col>

        <v-col cols="12">
          <v-checkbox dense 
            v-model="raceData.RaceMiddleEastern"
            label="Middle Eastern or North African"
            @change="checkMiddleEastern"
          ></v-checkbox>
          <v-responsive v-if="raceData.RaceMiddleEastern">
            <v-row>
              <v-col cols="6" sm="4">
                <v-checkbox dense v-model="raceData.MiddleEasternDetailsLebanese" label="Lebanese" @change="checkMiddleEastern"></v-checkbox>
              </v-col>
              <v-col cols="6" sm="4">
                <v-checkbox dense v-model="raceData.MiddleEasternDetailsIranian" label="Iranian" @change="checkMiddleEastern"></v-checkbox>
              </v-col>
              <v-col cols="6" sm="4">
                <v-checkbox dense v-model="raceData.MiddleEasternDetailsEgyptian" label="Egyptian" @change="checkMiddleEastern"></v-checkbox>
              </v-col>
              <v-col cols="6" sm="4">
                <v-checkbox dense v-model="raceData.MiddleEasternDetailsSyrian" label="Syrian" @change="checkMiddleEastern"></v-checkbox>
              </v-col>
              <v-col cols="6" sm="4">
                <v-checkbox dense v-model="raceData.MiddleEasternDetailsIraqi" label="Iraqi" @change="checkMiddleEastern"></v-checkbox>
              </v-col>
              <v-col cols="6" sm="4">
                <v-checkbox dense v-model="raceData.MiddleEasternDetailsIsraeli" label="Israeli" @change="checkMiddleEastern"></v-checkbox>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="raceData.DetailsMiddleEastern"
                  label="Enter details (e.g., Moroccan, Yemeni, Kurdish, etc.)"
                  @input="checkMiddleEastern"
                  dense
                ></v-text-field>
                <v-alert v-if="raceData.middleEasternError" type="error" dense>At least one subcategory or detail is required</v-alert>
              </v-col>
            </v-row>
          </v-responsive>
        </v-col>

        <v-col cols="12">
          <v-checkbox dense 
            v-model="raceData.RacePacificIslander"
            label="Native Hawaiian or Pacific Islander"
            @change="checkPacificIslander"
          ></v-checkbox>
          <v-responsive v-if="raceData.RacePacificIslander">
            <v-row>
              <v-col cols="6" sm="4">
                <v-checkbox dense v-model="raceData.PacificIslanderDetailsNativeHawaiian" label="Native Hawaiian" @change="checkPacificIslander"></v-checkbox>
              </v-col>
              <v-col cols="6" sm="4">
                <v-checkbox dense v-model="raceData.PacificIslanderDetailsSamoan" label="Samoan" @change="checkPacificIslander"></v-checkbox>
              </v-col>
              <v-col cols="6" sm="4">
                <v-checkbox dense v-model="raceData.PacificIslanderDetailsTongan" label="Tongan" @change="checkPacificIslander"></v-checkbox>
              </v-col>
              <v-col cols="6" sm="4">
                <v-checkbox dense v-model="raceData.PacificIslanderDetailsFijian" label="Fijian" @change="checkPacificIslander"></v-checkbox>
              </v-col>
              <v-col cols="6" sm="4">
                <v-checkbox dense v-model="raceData.PacificIslanderDetailsChamorro" label="Chamorro" @change="checkPacificIslander"></v-checkbox>
              </v-col>
              <v-col cols="6" sm="4">
                <v-checkbox dense v-model="raceData.PacificIslanderDetailsMarshallese" label="Marshallese" @change="checkPacificIslander"></v-checkbox>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="raceData.DetailsPacificIslander"
                  label="Enter details (e.g., Chuukese, Palauan, Tahitian, etc.)"
                  @input="checkPacificIslander"
                  dense
                ></v-text-field>
                <v-alert v-if="raceData.pacificIslanderError" type="error" dense>At least one subcategory or detail is required</v-alert>
              </v-col>
            </v-row>
          </v-responsive>
        </v-col>

        <v-col cols="12">
          <v-checkbox dense 
            v-model="raceData.RaceWhite"
            label="White"
            @change="checkWhite"
          ></v-checkbox>
          <v-responsive v-if="raceData.RaceWhite">
            <v-row>
              <v-col cols="6" sm="4">
                <v-checkbox dense v-model="raceData.WhiteDetailsEnglish" label="English" @change="checkWhite"></v-checkbox>
              </v-col>
              <v-col cols="6" sm="4">
                <v-checkbox dense v-model="raceData.WhiteDetailsGerman" label="German" @change="checkWhite"></v-checkbox>
              </v-col>
              <v-col cols="6" sm="4">
                <v-checkbox dense v-model="raceData.WhiteDetailsItalian" label="Italian" @change="checkWhite"></v-checkbox>
              </v-col>
              <v-col cols="6" sm="4">
                <v-checkbox dense v-model="raceData.WhiteDetailsIrish" label="Irish" @change="checkWhite"></v-checkbox>
              </v-col>
              <v-col cols="6" sm="4">
                <v-checkbox dense v-model="raceData.WhiteDetailsPolish" label="Polish" @change="checkWhite"></v-checkbox>
              </v-col>
              <v-col cols="6" sm="4">
                <v-checkbox dense v-model="raceData.WhiteDetailsScottish" label="Scottish" @change="checkWhite"></v-checkbox>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="raceData.DetailsWhite"
                  label="Enter details (e.g., French, Norwegian, etc.)"
                  @input="checkWhite"
                  dense
                ></v-text-field>
                <v-alert v-if="raceData.whiteError" type="error" dense>At least one subcategory or detail is required</v-alert>
              </v-col>
            </v-row>
          </v-responsive>
        </v-col>

      </v-row>
    </v-form>
  </v-container>
</template>

<script>
export default {
  props : {
    raceDataprops : Object
  },
  mounted(){
  },
  data() {
    return {
      raceData : {
        RaceAmericanIndian: false,
        DetailsAmericanIndian: '',
        RaceAsian: false,
        AsianDetailsChinese: false,
        AsianDetailsIndian: false,
        AsianDetailsVietnamese: false,
        AsianDetailsKorean: false,
        AsianDetailsJapanese: false,
        AsianDetailsMalaysian: false,
        DetailsAsian: '',
        asianError: false,
        RaceBlack: false,
        BlackDetailsAfricanAmerican: false,
        BlackDetailsNigerian: false,
        BlackDetailsEthiopian: false,
        BlackDetailsJamaican: false,
        BlackDetailsSomali: false,
        BlackDetailsHaitian: false,
        DetailsBlack: '',
        blackError: false,
        RaceHispanic: false,
        HispanicDetailsMexican: false,
        HispanicDetailsPuertoRican: false,
        HispanicDetailsCuban: false,
        HispanicDetailsDominican: false,
        HispanicDetailsSalvadoran: false,
        HispanicDetailsGuatemalan: false,
        DetailsHispanic: '',
        hispanicError: false,
        RaceMiddleEastern: false,
        MiddleEasternDetailsLebanese: false,
        MiddleEasternDetailsIranian: false,
        MiddleEasternDetailsEgyptian: false,
        MiddleEasternDetailsSyrian: false,
        MiddleEasternDetailsIraqi: false,
        MiddleEasternDetailsIsraeli: false,
        DetailsMiddleEastern: '',
        middleEasternError: false,
        RacePacificIslander: false,
        PacificIslanderDetailsNativeHawaiian: false,
        PacificIslanderDetailsSamoan: false,
        PacificIslanderDetailsTongan: false,
        PacificIslanderDetailsFijian: false,
        PacificIslanderDetailsChamorro: false,
        PacificIslanderDetailsMarshallese: false,
        DetailsPacificIslander: '',
        pacificIslanderError: false,
        RaceWhite: false,
        WhiteDetailsEnglish: false,
        WhiteDetailsGerman: false,
        WhiteDetailsItalian: false,
        WhiteDetailsIrish: false,
        WhiteDetailsPolish: false,
        WhiteDetailsScottish: false,
        DetailsWhite: '',
        whiteError: false
      }
    };
  },
  watch: {
    raceDataprops(val) {
      this.raceData = val;
    },
    'raceData.RaceAmericanIndian'() {
      this.emitData();
    },
    'raceData.DetailsAmericanIndian'() {
      this.emitData();
    },
    'raceData.RaceAsian'() {
      this.emitData();
    },
    'raceData.AsianDetailsChinese'() {
      this.emitData();
    },
    'raceData.AsianDetailsIndian'() {
      this.emitData();
    },
    'raceData.AsianDetailsVietnamese'() {
      this.emitData();
    },
    'raceData.AsianDetailsKorean'() {
      this.emitData();
    },
    'raceData.AsianDetailsJapanese'() {
      this.emitData();
    },
    'raceData.AsianDetailsMalaysian'() {
      this.emitData();
    },
    'raceData.DetailsAsian'() {
      this.emitData();
    },
    'raceData.RaceBlack'() {
      this.emitData();
    },
    'raceData.BlackDetailsAfricanAmerican'() {
      this.emitData();
    },
    'raceData.BlackDetailsNigerian'() {
      this.emitData();
    },
    'raceData.BlackDetailsEthiopian'() {
      this.emitData();
    },
    'raceData.BlackDetailsJamaican'() {
      this.emitData();
    },
    'raceData.BlackDetailsSomali'() {
      this.emitData();
    },
    'raceData.BlackDetailsHaitian'() {
      this.emitData();
    },
    'raceData.DetailsBlack'() {
      this.emitData();
    },
    'raceData.RaceHispanic'() {
      this.emitData();
    },
    'raceData.HispanicDetailsMexican'() {
      this.emitData();
    },
    'raceData.HispanicDetailsPuertoRican'() {
      this.emitData();
    },
    'raceData.HispanicDetailsCuban'() {
      this.emitData();
    },
    'raceData.HispanicDetailsDominican'() {
      this.emitData();
    },
    'raceData.HispanicDetailsSalvadoran'() {
      this.emitData();
    },
    'raceData.HispanicDetailsGuatemalan'() {
      this.emitData();
    },
    'raceData.DetailsHispanic'() {
      this.emitData();
    },
    'raceData.RaceMiddleEastern'() {
      this.emitData();
    },
    'raceData.MiddleEasternDetailsLebanese'() {
      this.emitData();
    },
    'raceData.MiddleEasternDetailsIranian'() {
      this.emitData();
    },
    'raceData.MiddleEasternDetailsEgyptian'() {
      this.emitData();
    },
    'raceData.MiddleEasternDetailsSyrian'() {
      this.emitData();
    },
    'raceData.MiddleEasternDetailsIraqi'() {
      this.emitData();
    },
    'raceData.MiddleEasternDetailsIsraeli'() {
      this.emitData();
    },
    'raceData.DetailsMiddleEastern'() {
      this.emitData();
    },
    'raceData.RacePacificIslander'() {
      this.emitData();
    },
    'raceData.PacificIslanderDetailsNativeHawaiian'() {
      this.emitData();
    },
    'raceData.PacificIslanderDetailsSamoan'() {
      this.emitData();
    },
    'raceData.PacificIslanderDetailsTongan'() {
      this.emitData();
    },
    'raceData.PacificIslanderDetailsFijian'() {
      this.emitData();
    },
    'raceData.PacificIslanderDetailsChamorro'() {
      this.emitData();
    },
    'raceData.PacificIslanderDetailsMarshallese'() {
      this.emitData();
    },
    'raceData.DetailsPacificIslander'() {
      this.emitData();
    },
    'raceData.RaceWhite'() {
      this.emitData();
    },
    'raceData.WhiteDetailsEnglish'() {
      this.emitData();
    },
    'raceData.WhiteDetailsGerman'() {
      this.emitData();
    },
    'raceData.WhiteDetailsItalian'() {
      this.emitData();
    },
    'raceData.WhiteDetailsIrish'() {
      this.emitData();
    },
    'raceData.WhiteDetailsPolish'() {
      this.emitData();
    },
    'raceData.WhiteDetailsScottish'() {
      this.emitData();
    },
    'raceData.DetailsWhite'() {
      this.emitData();
    }
  },
  methods: {
    emitData() {
      this.$emit('update:raceData', this.raceData);
    },
    validateForm() {
      this.checkAsian();
      this.checkBlack();
      this.checkHispanic();
      this.checkMiddleEastern();
      this.checkPacificIslander();
      this.checkWhite();
      if (
        !this.raceData.asianError &&
        !this.raceData.blackError &&
        !this.raceData.hispanicError &&
        !this.raceData.middleEasternError &&
        !this.raceData.pacificIslanderError &&
        !this.raceData.whiteError
      ) {
        this.emitData();
      }
    },
    checkAsian() {
      this.raceData.asianError =
        this.raceData.RaceAsian &&
        !(
          this.raceData.AsianDetailsChinese ||
          this.raceData.AsianDetailsIndian ||
          this.raceData.AsianDetailsVietnamese ||
          this.raceData.AsianDetailsKorean ||
          this.raceData.AsianDetailsJapanese ||
          this.raceData.AsianDetailsMalaysian ||
          this.raceData.DetailsAsian
        );
    },
    checkBlack() {
      this.raceData.blackError =
        this.raceData.RaceBlack &&
        !(
          this.raceData.BlackDetailsAfricanAmerican ||
          this.raceData.BlackDetailsNigerian ||
          this.raceData.BlackDetailsEthiopian ||
          this.raceData.BlackDetailsJamaican ||
          this.raceData.BlackDetailsSomali ||
          this.raceData.BlackDetailsHaitian ||
          this.raceData.DetailsBlack
        );
    },
    checkHispanic() {
      this.raceData.hispanicError =
        this.raceData.RaceHispanic &&
        !(
          this.raceData.HispanicDetailsMexican ||
          this.raceData.HispanicDetailsPuertoRican ||
          this.raceData.HispanicDetailsCuban ||
          this.raceData.HispanicDetailsDominican ||
          this.raceData.HispanicDetailsSalvadoran ||
          this.raceData.HispanicDetailsGuatemalan ||
          this.raceData.DetailsHispanic
        );
    },
    checkMiddleEastern() {
      this.raceData.middleEasternError =
        this.raceData.RaceMiddleEastern &&
        !(
          this.raceData.MiddleEasternDetailsLebanese ||
          this.raceData.MiddleEasternDetailsIranian ||
          this.raceData.MiddleEasternDetailsEgyptian ||
          this.raceData.MiddleEasternDetailsSyrian ||
          this.raceData.MiddleEasternDetailsIraqi ||
          this.raceData.MiddleEasternDetailsIsraeli ||
          this.raceData.DetailsMiddleEastern
        );
    },
    checkPacificIslander() {
      this.raceData.pacificIslanderError =
        this.raceData.RacePacificIslander &&
        !(
          this.raceData.PacificIslanderDetailsNativeHawaiian ||
          this.raceData.PacificIslanderDetailsSamoan ||
          this.raceData.PacificIslanderDetailsTongan ||
          this.raceData.PacificIslanderDetailsFijian ||
          this.raceData.PacificIslanderDetailsChamorro ||
          this.raceData.PacificIslanderDetailsMarshallese ||
          this.raceData.DetailsPacificIslander
        );
    },
    checkWhite() {
      this.raceData.whiteError =
        this.raceData.RaceWhite &&
        !(
          this.raceData.WhiteDetailsEnglish ||
          this.raceData.WhiteDetailsGerman ||
          this.raceData.WhiteDetailsItalian ||
          this.raceData.WhiteDetailsIrish ||
          this.raceData.WhiteDetailsPolish ||
          this.raceData.WhiteDetailsScottish ||
          this.raceData.DetailsWhite
        );
    }
  }
};
</script>

<style scoped>
</style>
