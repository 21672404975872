<template>
    <div class='ma-2'>
      <v-card-title>
        Users
      </v-card-title>
  
      <v-card>
        <v-card-title>
  
          <v-spacer></v-spacer>
          <v-text-field
            dense
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
          ></v-text-field>
  
        </v-card-title>
  
        <v-data-table
          item-key="row_id"
          v-model="selected"
          :loading='loading'
          loading-text="Loading... Please wait"
          multi-sort
          :sort-by="sortby"
          :sort-desc="sortDesc"
          :headers="headers"
          :items="users"
          :search="search"
        >
  
          <template v-slot:item.actions="{ item }">
            <!-- <v-icon
              small
              class="mr-2"
              @click="editItem(item)"
            >
              mdi-pencil
            </v-icon> -->
            <v-icon
              small
              @click="deleteItem(item)"
            >
              mdi-delete
            </v-icon>
          </template>
        </v-data-table>
      </v-card>
  
      <v-dialog v-model="dialog">
        <v-card>
          <v-card-title>
            <span class="text-h5">Edit</span>
          </v-card-title>
  
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    dense
                    v-model="editedItem.Account"
                    label="Account"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    dense
                    v-model="editedItem.County"
                    label="County"
                  ></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    dense
                    v-model="editedItem.FiscalYear"
                    label="Fiscal Year"
                  ></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    dense
                    v-model="editedItem.FiscalMonth"
                    label="Fiscal Month "
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    dense
                    v-model="editedItem.CurrentDocNum"
                    label="Current Doc Number"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    dense
                    v-model="editedItem.GLAccount"
                    label="GL Account"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    dense
                    v-model="editedItem.GLAmount"
                    label="GL Amount"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    dense
                    v-model="editedItem.InvoiceDate"
                    label="Invoice Date"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    dense
                    v-model="editedItem.InvoiceNum"
                    label="Invoice Number"
                  ></v-text-field>
                </v-col>
  
                <v-col cols="12">
                  <v-text-field
                    dense
                    v-model="editedItem.MasterIndex"
                    label="Master Index"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    dense
                    v-model="editedItem.ProcessDate"
                    label="Process Date"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    dense
                    v-model="editedItem.Project"
                    label="Project"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    dense
                    v-model="editedItem.ReferenceDocNum"
                    label="Reference Doc Number"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    dense
                    v-model="editedItem.ReverseCode"
                    label="Reverse Code"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    dense
                    v-model="editedItem.Subobject"
                    label="Sub object"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    dense
                    v-model="editedItem.Subsubobject"
                    label="Sub sub object"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    dense
                    v-model="editedItem.SumGLAmount"
                    label="Sum GL Amount"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    dense
                    v-model="editedItem.TransactionCode"
                    label="Transaction Code"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
  
                  <v-autocomplete
                    @change='onVendorNumberChnage'
                    dense
                    v-model="editedItem.VendorNum"
                    item-text='vendor_id'
                    label="Vendor Number"
                    :items="vendors"
                    variant="underlined"
                  ></v-autocomplete>
  
                </v-col>
                <v-col cols="12">
                  <v-autocomplete
                    @change='onVendorNameChange'
                    dense
                    v-model="editedItem.VendorName"
                    item-text='name'
                    label="Vendor Name"
                    :items="vendors"
                    variant="underlined"
                  ></v-autocomplete>
  
                </v-col>
  
                <v-col cols="12">
                  <v-text-field
                    dense
                    v-model="editedItem.VendorNumSuffix"
                    label="Vendor Num Suffix"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
  
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="blue darken-1"
              text
              @click="close"
            >
              Cancel
            </v-btn>
            <v-btn
              color="blue darken-1"
              text
              @click="save"
            >
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
  
      <v-dialog
        v-model="dialogDelete"
        max-width="500px"
      >
        <v-card>
          <v-card-title class="text-h5">Are you sure you want to delete this item?</v-card-title>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="blue darken-1"
              text
              @click="closeDelete"
            >Cancel</v-btn>
            <v-btn
              color="blue darken-1"
              text
              @click="deleteItemConfirm"
            >OK</v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </template>
  
  
  <script>
  import moment from "moment";
  import { getAllUsers } from "../dataservices/adminDataService";
  export default {
    watch: {
      dialog(val) {
        val || this.close();
      },
      dialogDelete(val) {
        val || this.closeDelete();
      },
    },
    methods: {
      goToListAssoicatedContractsPage(item) {
        this.$router.push("contract/" + item.id);
      },
  
      formatdateCreated(dateCreated) {
        return moment(dateCreated).fromNow();
      },
  
      onVendorNumberChnage(item) {
        this.editedItem.VendorName = this.vendors.find(
          (v) => v.vendor_id === item
        ).name;
      },
      onVendorNameChange(item) {
        this.editedItem.VendorNum = this.vendors.find(
          (v) => v.name === item
        ).vendor_id;
      },
      save() {},
    
  
      deleteItemConfirm() {
        this.invoices.splice(this.editedIndex, 1);
        this.closeDelete();
      },
  
      close() {
        this.dialog = false;
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem);
          this.editedIndex = -1;
        });
      },
  
      closeDelete() {
        this.dialogDelete = false;
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem);
          this.editedIndex = -1;
        });
      },
  
    },
    data() {
      return {
        vendors: [],
        editedItem: {},
        dialog: false,
        dialogDelete: false,
        selected: [],
        loading: true,
        sortby: [],
        sortDesc: [],
        headers: [
          {
            text: "Uid",
            align: "start",
            sortable: true,
            value: "uid",
          },
          {
            text: "Display Name",
            align: "start",
            sortable: true,
            value: "displayName",
          },
          {
            text: "Email",
            align: "start",
            sortable: true,
            value: "email",
          },
          {
            text: "Creation Time",
            align: "start",
            sortable: true,
            value: "metadata.creationTime",
          },
          {
            text: "Last Sign In Time",
            align: "start",
            sortable: true,
            value: "metadata.lastSignInTime",
          },

          {
            text: "Role",
            align: "start",
            sortable: true,
            value: "customClaims.role",
          },
  
          //{ text: "Actions", value: "actions", sortable: false },
        ],
        search: null,
        users: [],
      };
    },
    async mounted() {
      this.users= await getAllUsers();
      this.loading = false;
    },
  };
  </script>