import Vue from 'vue'
import Vuex from 'vuex'
import { getField, updateField } from "vuex-map-fields";

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
	claims: null,
		role: null,
	items: [
		{
			title: "Home",
			icon: "mdi-home",
			to: "/",
			active: true,
			requireAdmin: false,
		},
		{
			title: "Case Information",
			icon: "mdi-folder",
			to: "/cases",
			active: true,
			requireAdmin: false,
		},
		{
			title: "Invocing",
			icon: "mdi-receipt",
			to: "/invocing",
			active: false,
			requireAdmin: false,
		},
		{
			title: "Timesheet",
			icon: "mdi-clock",
			to: "/timesheet",
			active: true,
			requireAdmin: false,
		},
		{
			title: "Reports",
			icon: "mdi-clipboard",
			to: "/reports",
			active: true,
			requireAdmin: false,
		},
		
		{
			title: "Help",
			icon: "mdi-help",
			to: "/help",
			active: true,
			requireAdmin: false,
		},
		{
		icon: "mdi-account-eye",
		active: false,
		title: "Admin Only",
		requireAdmin: true,
		items: [
			
			{
				title: "Admin Settings",
				icon: "mdi-account-eye",
				to: "/admin",
				requireAdmin: true,
				requireSuperAdmin : false,
			},
			{
				title: "User List",
				icon: "mdi-users",
				to: "/users",
				requireAdmin: true,
				requireSuperAdmin : false,
			},
			{
				title: "Org Settings",
				icon: "mdi-account-eye",
				to: "/org-settings",
				requireAdmin: true,
				requireSuperAdmin : true,
			},
			{
				title: "Data Feed",
				icon: "mdi-account-eye",
				to: "/datafeed",
				requireAdmin: true,
				requireSuperAdmin : true,
			},{
				title: "All Activity Log",
				icon: "mdi-tools",
				to: "/activityLog",
				active: true,
				requireAdmin: true,
				requireSuperAdmin : true,
			},
			{
				title: "Transfer Log",
				icon: "mdi-tools",
				to: "/transferLog",
				active: true,
				requireAdmin: true,
				requireSuperAdmin : true,
			},
		],
	}, ],
    user: {
			loggedIn: false,
			data: null,
		},
  },
  getters: {
	getField,
	role(state) {
		return state.role;
	},
	claims(state) {
		return state.claims;
	},
	user(state) {
		return state.user;
	},
	},
	mutations: {
		updateField,
		SET_LOGGED_IN(state, value) {
			state.user.loggedIn = value;
		},
		set_role(state, data) {
			state.role = data;
		},
		set_claims(state, data) {
			state.claims = data;
		},
		SET_USER(state, data) {
			state.user.data = data;
		},

		set_admin_item(state, data) {
			if (data === "User") {
				state.items = state.items.filter((item) => !item.requireAdmin);
		} else if (data === "Admin") {
			const adminSettings = state.items[6].items.filter((item) => !item.requireSuperAdmin);
			state.items[6].items = adminSettings;
			}
		},
		set_item(state, data) {
			let target = state.items.find((i) => i.title === data);
			if (target) {
				target.active = true;
			} else {
				state.items.forEach((item) => (item.active = false));
			}
		},

	},
	actions: {
		async filterAdminItem({ commit }, role) {
			commit("set_admin_item", role);
		},
		async fetchUser({ commit }, user) {
			commit("SET_LOGGED_IN", user !== null);
			if (user) {
				commit("SET_USER", {
					displayName: user.displayName,
					email: user.email,
				});
			} else {
				commit("SET_USER", null);
			}
		},
	},
  modules: {
  }
})
