<template>
  <div>
    <v-container>
      <v-row>
        <v-col cols="12">
          <v-text-field
            dense
            v-model="editedItem.SWV"
            label="SWV"
            :disabled="editMode"
          ></v-text-field>
        </v-col>
        <v-col cols="12">
          <v-text-field
            dense
            v-model="editedItem.Contractor"
            label="Contractor Name"
          ></v-text-field>
        </v-col>
        <v-col cols="12">
          <v-text-field
            dense
            v-model="editedItem.WSBA"
            label="WSBA"
          ></v-text-field>
        </v-col>
        <v-col cols="12">
          <v-text-field
            dense
            v-model="editedItem.ProgramTitle"
            label="Program Title"
          ></v-text-field>
        </v-col>
        <v-col cols="12">
          <v-text-field
            dense
            v-model="editedItem.EmailAddress"
            label="Email Address"
          ></v-text-field>
        </v-col>

        <v-col cols="12">
          <v-text-field
            dense
            v-model="editedItem.ContractStatus"
            label="Contract Status"
          ></v-text-field>
        </v-col>
        <v-col cols="12">
          <v-text-field
            dense
            v-model="editedItem.GLAccount"
            label="GL Account"
          ></v-text-field>
        </v-col>
        <v-col cols="12">
          <v-text-field
            dense
            v-model="editedItem.OrgName"
            label="Org Name"
          ></v-text-field>
        </v-col>
        <v-col cols="12">
          <v-text-field
            dense
            v-model="editedItem.InvoiceDate"
            label="Invoice Date"
          ></v-text-field>
        </v-col>
        <v-col cols="6">
          <v-text-field
            dense
            v-model="editedItem.StreetAddress"
            label="Street Address"
          ></v-text-field>
        </v-col>

        <v-col cols="6">
          <v-text-field
            dense
            v-model="editedItem.PO_Box"
            label="PO Box"
          ></v-text-field>
        </v-col>
        <v-col cols="12">
          <v-text-field
            dense
            v-model="editedItem.SteRmAptFlMS"
            label="SteRmAptFlMS"
          ></v-text-field>
        </v-col>
        <v-col cols="4">
          <v-text-field
            dense
            v-model="editedItem.City"
            label="City"
          ></v-text-field>
        </v-col>
        <v-col cols="4">
          <v-text-field
            dense
            v-model="editedItem.State_Province"
            label="State Province"
          ></v-text-field>
        </v-col>
        <v-col cols="4">
          <v-text-field
            dense
            v-model="editedItem.PostalCode"
            label="Postal Code"
          ></v-text-field>
        </v-col>
        <v-col
          v-if='editMode'
          cols='6'
        >
          <v-card
            class="mx-auto"
            flat
          >

            <v-list
              subheader
              two-line
            >
              <v-subheader>Uploaded Files</v-subheader>
              <v-subheader v-if='filesOnserver.length === 0'><v-icon>mdi-exclamation-thick</v-icon>No File Avaiable</v-subheader>
              <v-list-item
                v-for="file in filesOnserver"
                :key="file.ID"
              >
                <v-list-item-avatar>
                  <v-icon>mdi-clipboard-text</v-icon>
                </v-list-item-avatar>

                <v-list-item-content>
                  <v-list-item-title>{{formatFIleName(file.file_name)}}</v-list-item-title>
                  <v-list-item-subtitle>Uploaded: {{formatDate(file.dateCreated)}}</v-list-item-subtitle>
                </v-list-item-content>

                <v-list-item-action>
                  <v-btn icon>
                    <v-icon @click='getFile(file.file_name)'>mdi-download</v-icon>
                  </v-btn>
                </v-list-item-action>
              </v-list-item>
            </v-list>
          </v-card>
        </v-col>
        <v-col :cols="editMode ? 6 : 12">
          <v-file-input
            @change='onFileChange'
            v-model='uploadfiles'
            chips
            label="Upload Documents"
            multiple
            dense
          ></v-file-input>
        </v-col>
      </v-row>
    </v-container>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        v-if='editMode'
        color="blue darken-1"
        text
        @click="close"
      >
        Cancel
      </v-btn>
      <v-btn
        color="blue darken-1"
        text
        @click="action"
        @loading='createOrEditLoading'
      >
        {{editMode ? "Edit" : "Create Contractor"}}
      </v-btn>
    </v-card-actions>
  </div>
</template>

<script>
import moment from "moment";
import {
  createContractor,
  updateContractors,
  getContractorFile,
  getFile,
} from "../dataservices/contractorsDataservices";
export default {
  props: {
    editMode: Boolean,
    inComeEditedItem: Object,
    id : Number,
  },
  async mounted() {
    if (this.editMode) {
      this.editedItem = this.inComeEditedItem;
      await this.getContractorFile();
    }
  },
  data() {
    return {
      filesOnserver: [],
      createOrEditLoading: false,
      uploadfiles: [],
      editedItem: {
        filesBase64: [],
        ProgramTitle: "",
        Contractor: "",
        EmailAddress: "",
        ContractStatus: "",
        OrgName: "",
        StreetAddress: "",
        PO_Box: "",
        SteRmAptFlMS: "",
        City: "",
        State_Province: "",
        PostalCode: "",
        AttyNames: "",
        Manager: "",
        WSBA: "",
      },
    };
  },
  methods: {
    close() {
      this.$emit("close");
    },
    action() {
      this.createOrEditLoading = true;
      this.editMode ? this.edit() : this.create();
    },

    async edit() {
      await updateContractors(this.editedItem, this.editedItem.id);
      this.createOrEditLoading = false;
      location.reload();
    },
    async getContractorFile() {
      this.filesOnserver = await getContractorFile(this.editedItem.id);
    },
    async create() {
      await createContractor(this.editedItem);
      this.createOrEditLoading = false;
      location.reload();
    },
    formatDate(date) {
      return moment(date).fromNow();
    },
    async getFile(fileName) {
      let url = await getFile(fileName);
      window.open(url, "_blank").focus();
    },
    formatFIleName(fileName) {
      let formmatedName = fileName.split("/");
      return formmatedName[2];
    },
    onFileChange() {
      this.editedItem.filesBase64 = [];
      this.uploadfiles.forEach((file) => {
        //convert to base64
        this.getBase64(file, (base64) => {
          this.editedItem.filesBase64.push({
            fileName: file.name,
            fileType: file.type,
            baseString: base64,
          });
        });
      });
    },
    getBase64(file, callback) {
      const reader = new FileReader();
      reader.addEventListener("load", () =>
        callback(reader.result.replace("data:", "").replace(/^.+,/, ""))
      );
      reader.readAsDataURL(file);
    },
  },
};
</script>

<style>
</style>