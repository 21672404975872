<template>
<v-container>
  <v-btn to="/newCase" class="mt-5 mb-5" color="primary">
    New Case Information
  </v-btn>
<v-card>
      <v-card-title>
      Case Information
      <v-spacer></v-spacer>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Search"
        single-line
        hide-details
      ></v-text-field>
      </v-card-title>
  <v-data-table
  :loading="loading"
    :headers="headers"
    :items="cases"
    :items-per-page="30"
    class="elevation-1"
    :search="search"
  >

  <template v-slot:item.action="{item }">
        <v-btn
        small
          :to="`/case/${item.CaseID}`"
        >
          Edit
        </v-btn>
        
      </template>


</v-data-table>
  </v-card>



</v-container>

</template>

<script>

import { getCases } from "../dataservices/caseDataService";
export default {
 
  async mounted(){
    this.cases = await getCases();
    this.loading = false;
  },
  data(){
        return{
          loading : true,
          cases : [],
            search : "",
            headers : [ 
                 { text: 'Case Id', value: 'CaseID' },
                 { text: 'Created Date', value: 'CreateDate' },
                 { text: 'Star Code', value: 'StarCode' },
                 { text: 'Assign Date', value: 'AssignDate' },
                 { text: 'Assignment County', value: 'AssignmentCounty' },
                 { text: 'Assignment Type', value: 'AssignmentType' },
                 { text: 'Action', value: 'action' },
            ],
     
        }
    },
};
</script>

<style>
</style>