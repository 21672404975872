import axios from 'axios';

const createTimeEntry = async (data) => {
     const result = await axios.post("https://dp-02-db2a5.wl.r.appspot.com/internal/createTimeEntry",data)
     return result.data;
}

const getTimeEntry = async () => {
     const result = await axios.post("https://dp-02-db2a5.wl.r.appspot.com/internal/getTimeEntry")
     return result.data;
}

const deleteTimeEntry = async (id) => {
     const result = await axios.delete("https://dp-02-db2a5.wl.r.appspot.com/internal/deleteTimeEntry",{
          id : id
     })
     return result.data;
}


export {createTimeEntry, getTimeEntry, deleteTimeEntry}