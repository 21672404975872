<template>
    <v-card>
      <v-card-title>
      Case Transfer Log
      <v-spacer></v-spacer>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Search"
        single-line
        hide-details
      ></v-text-field>
      </v-card-title>
  <v-data-table
    :headers="headers"
    :items="logs"
    :items-per-page="15"
    class="elevation-1"
    :search="search"
  >
  <template v-slot:item.timesince="{item }">
       {{ daysElapsed(item.timestamp) }} Days
      </template>
  
  <template v-slot:item.timestamp="{item }">
       {{ formatDate(item.timestamp) }}
      </template>

  <template v-slot:item.from="{item }">
     

        <a :href='"/case/"+JSON.parse(item.requestBody).fromid'>{{JSON.parse(item.requestBody).fromid }}</a>

      </template>


      <template v-slot:item.to="{item }">
        <a :href='"/case/"+JSON.parse(item.requestBody).to'>{{JSON.parse(item.requestBody).to }}</a>
      </template>

</v-data-table>
  </v-card>
</template>

<script>
import { getTransferLogs } from '../dataservices/adminDataService';

export default {
methods: {
  daysElapsed(isoTimestamp){
    let pastDate = new Date(isoTimestamp);
    let currentDate = new Date();
    let differenceInMillis = currentDate - pastDate;

// Convert milliseconds to days
let millisecondsPerDay = 24 * 60 * 60 * 1000;
return Math.floor(differenceInMillis / millisecondsPerDay);
  },
  formatDate(isoTimestamp){
    let date = new Date(isoTimestamp);
    let month = String(date.getUTCMonth() + 1).padStart(2, '0'); // Months are zero-based
let day = String(date.getUTCDate()).padStart(2, '0');
let year = date.getUTCFullYear();
let hours = String(date.getUTCHours()).padStart(2, '0');
let minutes = String(date.getUTCMinutes()).padStart(2, '0');
let seconds = String(date.getUTCSeconds()).padStart(2, '0');

// Format the date and time
return `${month}/${day}/${year} ${hours}:${minutes}:${seconds}`;
  },
},
  async mounted(){
    this.logs = await getTransferLogs();
  },
    data(){
        return{
          logs : [],
            search : "",
            headers : [ 
                 { text: 'Date of Transfer', value: 'timestamp' },
                 { text: 'Days elapsed since Transfer', value: 'timesince' },
                 { text: 'From Case ID', value: 'from' },
                 { text: 'To Case Id', value: 'to' },
                 { text: 'Requestor', value: 'user' },
            ],
     
        }
    },
}
</script>

<style>

</style>