<template>
     <div>
        <v-card-title>
          Create New Case Information
        </v-card-title>
      <ContractForm :editMode='false' />
    </div>
</template>


<script>
import ContractForm from "../components/EntryForm.vue";

export default {
  components: {
    ContractForm,
  },
 
  
};
</script>
